import "@babel/polyfill";
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;

import "bootstrap/js/dist/util";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/modal";


import ellipsis from "ellipsis.js";
ellipsis({
    className: '.clamp_4_lines',
    lines: 4,
    debounce: 10,
    responsive: true,
    break_word: false
});

//TODO: clamp anpassen auf kleinen Bildschirmen
ellipsis({
   className: '.clamp_3_lines',
    lines: 3,
    debounce: 10,
    responsive: true,
    break_word: false
});
$(window).trigger('resize');

require("./other/button-animation");
//require("./other/chart.js");


//removes bug of cookie modal where scrolling is disabled after closing
$(document).on('lity:close', function(event, instance) {
    $("body").removeClass("moove_gdpr_overflow");
});

/**
 *
 * TODO: OPT OUT COOKIE FOR Google Analytics


// Set to the same value as the web property used on the site
var gaProperty = 'UA-146885398-1';

// Disable tracking if the opt-out cookie exists.
var disableStr = 'ga-disable-' + gaProperty;
if (document.cookie.indexOf(disableStr + '=true') > -1) {
    window[disableStr] = true;
}

// Opt-out function
function gaOptout() {
    document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStr] = true;
}

**/