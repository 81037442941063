import Snap from 'snapsvg';
import { detect } from "detect-browser";



const browser = detect();

// handle the case where we don't detect the browser
/** Button Effekt aktuell nicht in Verwendung **/
if (false && browser && browser.name !== "ie" && browser.name !== "ios") {
    document.querySelectorAll('.btn.btn-primary').forEach((btn) => {

        var cln = btn.cloneNode(true);
        cln.style.position = "absolute";
        cln.style.left = "-1000px";
        cln.style.top = "-1000px";
        document.body.appendChild(cln);

        let btnText = btn.textContent;
        if(!btnText && btn.tagName.toLowerCase() === "input") {
            btnText = btn.value;
        }
        let width = cln.clientWidth;
        let height = cln.clientHeight;
        let href = btn.getAttribute("href") === null ? "" : btn.getAttribute("href");
        let fontSize = 16;
        let svg = Snap(width, height);
        svg.node.style.cursor = "pointer";
        $(svg.node).addClass("svg-btn svg-btn-primary");

        let color_text = "#243588";
        let color_background = "#FFFFFF";

        let stroke_width = "0.1em";

        let color_text_hover = "#FFFFFF";
        let color_background_hover = "#005f78";

        let maskOffset = btn.clientHeight;
        let link_container = svg.el("a", {"xlink:href": href});

        let border = svg
            .rect(0, 0, width, height)
            .attr({ 'fill': 'none', 'stroke-width': 0, 'stroke': "none", 'shape-rendering': "crispEdges"});

        let mask = svg
            .path(`M0,0 L${width + maskOffset},0 L${width},${height} L-${maskOffset}, ${height} Z`)
            .attr({ 'fill': color_background, 'shape-rendering': "crispEdges" })
            .transform(`t-${width + maskOffset},0`);

        let maskInvert = mask
            .clone()
            .transform('t0,0');

        let text = svg
            .text(width / 2, ((height / 2) + fontSize * 0.3), btnText)
            .attr({ 'text-anchor': 'middle', 'alignment-baseline': 'baseline', 'font-size': fontSize, 'fill': color_text, 'shape-rendering': "crispEdges" });

        let button = svg
            .group(border, text);

        let borderMasked = border
            .clone()
            .attr({ 'fill': color_background_hover, 'fill-opacity': "0.7", 'shape-rendering': "crispEdges" });

        let textMasked = text
            .clone()
            .attr({ 'fill': color_text_hover, 'shape-rendering': "crispEdges" });

        let masked = svg
            .group(borderMasked, textMasked)
            .attr({ mask });

        svg.hover(
            () => {
                mask.attr('stroke-width', stroke_width);
                mask.stop().animate({ transform: 't0,0' }, 500, mina.easein );
                maskInvert.stop().animate({ transform: `t${width + maskOffset},0` }, 500, mina.easein )
            },
            () => {
                mask.stop().animate({ transform: `t-${width + maskOffset},0` }, 350, mina.easeout, () => mask.attr('stroke-width', 0));
                maskInvert.stop().animate({ transform: 't0,0' }, 350, mina.easeout)
            }
        );


        $(btn).on("change", function(){
            $(svg.node).find("text").each(function(){
                $(this).text($(btn).text());
            });
        });

        svg.click(() => btn.click());
        $(svg.node).insertAfter($(btn));
        let $link_container = $(svg.node).find("a");
        $link_container.nextAll().each(function() {
            $(this).appendTo($link_container);
        });
        btn.style.display = "none";
    });
}